import React, { useState, useEffect } from "react";
import "./assets/scss/main.scss";
import "./assets/scss/font.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import Header from "./containers/Header/Header";
import Home from "./containers/Home/Home";
import Footer from "./containers/Footer/Footer";
import AboutUs from "./containers/AboutUs/AboutUs";
import News from "./containers/News/News";
import Careers from "./containers/Careers/Careers";
import Contact from "./containers/Contact/Contact";

import CookiesLegalText from "./components/CookiesLegalText/CookiesLegalText";
import ReactGA from "react-ga4";
import RouteChangeTracker from "./RouteChangeTracker";
import PartnerStory from "./containers/PartnerStory/PartnerStory";
import TechnologiesAndProjects from "./containers/TechnologiesAndProjects/TechnologiesAndProjects";
import CompanyCulture from "./containers/CompanyCulture/CompanyCulture";
import PhpSenior from "./containers/ApplyPage/PhpSenior/PhpSenior";
import PhpSeniorForm from "./containers/ApplyPage/PhpSenior/PhpSeniorForm";
import PageNotFound from "./containers/PageNotFound/PageNotFound";
import PrivacyPolicy from "./containers/PrivacyPolicy/PrivacyPolicy";
import CookiePolicy from "./containers/CookiePolicy/CookiePolicy";

import BlogPost1 from "./components/BlogPosts/BlogPost1";
import BlogPost2 from "./components/BlogPosts/BlogPost2";
import BlogPost3 from "./components/BlogPosts/BlogPost3";
import BlogPost4 from "./components/BlogPosts/BlogPost4";
import BlogPost5 from "./components/BlogPosts/BlogPost5";
import BlogPost6 from "./components/BlogPosts/BlogPost6";
import BlogPost7 from "./components/BlogPosts/BlogPost7";
import BlogPost8 from "./components/BlogPosts/BlogPost8";
import BlogPost9 from "./components/BlogPosts/BlogPost9";
import BlogPost10 from "./components/BlogPosts/BlogPost10";
import BlogPost11 from "./components/BlogPosts/BlogPost11";
import BlogPost12 from "./components/BlogPosts/BlogPost12";

function App() {
  const [cookieAccepted, setCookieAccepted] = useState(false);
  const [gCookieAccepted, setGCookieAccepted] = useState(true);
  // const TRACKING_ID = "UA-174591087-1";
  const TRACKING_ID = "G-K4SNV2Y285";

  // window[`ga-disable-${TRACKING_ID}`] = true;
  useEffect(() => {
    if (cookieAccepted && gCookieAccepted) {
      ReactGA.initialize(TRACKING_ID);
    }
  }, [gCookieAccepted, cookieAccepted]);

  return (
    <div className="App">
      <Router>
        <RouteChangeTracker />
        <ScrollToTop />
        <Header />
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/about-us">
            <AboutUs />
          </Route>
          <Route exact path="/partner-story">
            <PartnerStory />
          </Route>
          <Route exact path="/insights">
            <News />
          </Route>
          {/* blog posts */}
          <Route exact path="/insights/team-building-2021">
            <BlogPost1 />
          </Route>
          <Route exact path="/insights/new-years-celebration-2022">
            <BlogPost2 />
          </Route>
          <Route exact path="/insights/mental-health-month-2022">
            <BlogPost3 />
          </Route>
          <Route exact path="/insights/cleanup-day-2022">
            <BlogPost4 />
          </Route>
          <Route exact path="/insights/open-house-day-2022">
            <BlogPost5 />
          </Route>
          <Route exact path="/insights/team-building-2022">
            <BlogPost6 />
          </Route>
          <Route exact path="/insights/new-years-celebration-2023">
            <BlogPost7 />
          </Route>
          <Route exact path="/insights/may-the-mindfulness-be-with-you">
            <BlogPost8 />
          </Route>
          <Route exact path="/insights/new-benefits-for-new-challenges">
            <BlogPost9 />
          </Route>
          <Route exact path="/insights/wrapping-up-the-year-together">
            <BlogPost10 />
          </Route>
          <Route exact path="/insights/fun-filled-family-day-at-the-office">
            <BlogPost11 />
          </Route>
          <Route exact path="/insights/run-for-little-heroes">
            <BlogPost12 />
          </Route>
          {/* blog posts end */}
          <Route exact path="/careers">
            <Careers />
          </Route>
          {/* apply for postion pages */}
          <Route exact path="/apply-php-senior">
            <PhpSenior />
          </Route>
          <Route exact path="/apply-php-senior-form">
            <PhpSeniorForm />
          </Route>
          {/* apply for postion pages end */}
          <Route exact path="/technologies-and-projects">
            <TechnologiesAndProjects />
          </Route>
          <Route exact path="/company-culture">
            <CompanyCulture />
          </Route>
          <Route exact path="/contact-us">
            <Contact />
          </Route>
          <Route exact path="/privacy-policy">
            <PrivacyPolicy />
          </Route>
          <Route exact path="/cookie-policy">
            <CookiePolicy />
          </Route>
          <Route path="*">
            <PageNotFound />
          </Route>
        </Switch>
        <Footer />

        <CookiesLegalText
          cookieAccepted={cookieAccepted}
          setCookieAccepted={setCookieAccepted}
          gCookieAccepted={gCookieAccepted}
          setGCookieAccepted={setGCookieAccepted}
        />
      </Router>
    </div>
  );
}

export default App;
