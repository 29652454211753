import blog1 from "../assets/images/blogs/blog1/header.jpg";
import blog2 from "../assets/images/blogs/blog2/header.jpg";
import blog3 from "../assets/images/blogs/blog3/header.jpg";
import blog4 from "../assets/images/blogs/blog4/header.jpg";
import blog5 from "../assets/images/blogs/blog5/header.jpg";
import blog6 from "../assets/images/blogs/blog6/header.jpg";
import blog7 from "../assets/images/blogs/blog7/header.jpg";
import blog8 from "../assets/images/blogs/blog8/header.jpg";
import blog9 from "../assets/images/blogs/blog9/header.png";
import blog10 from "../assets/images/blogs/blog10/header.png";
import blog11 from "../assets/images/blogs/blog11/1.webp";
import blog12 from "../assets/images/blogs/blog12/1.webp";
export const blogCardsData = [
  {
    id: 12,
    title: "Run for little heroes",
    subtitle: "Stafeta srcem 2024",
    imageUrl: blog12,
    dateCreated: "10.Jun 2024",
    url: "run-for-little-heroes",
  },
  {
    id: 11,
    title: "Fun filled family day at the office",
    subtitle: "Family day 2024",
    imageUrl: blog11,
    dateCreated: "16.May 2024",
    url: "fun-filled-family-day-at-the-office",
  },
  {
    id: 10,
    title: "Wrapping up the year together",
    subtitle: "365 new days, 365 new chances. Let's make each one count.",
    imageUrl: blog10,
    dateCreated: "08.Jan 2024",
    url: "wrapping-up-the-year-together",
  },
  {
    id: 9,
    title: "New benefits for new challenges",
    subtitle: "Benefits at Nearshore GM",
    imageUrl: blog9,
    dateCreated: "26.July 2023",
    url: "new-benefits-for-new-challenges",
  },
  {
    id: 8,
    title: "May the Mindfulness be with you",
    subtitle: "Nurturing Mental Health in May",
    imageUrl: blog8,
    dateCreated: "25.May 2023",
    url: "may-the-mindfulness-be-with-you",
  },
  {
    id: 7,
    title: "New Year’s magic at the office",
    subtitle: "",
    imageUrl: blog7,
    dateCreated: "11.Jan 2023",
    url: "new-years-celebration-2023",
  },
  {
    id: 6,
    title: "Turn on relax mode",
    subtitle: "Team building 2022",
    imageUrl: blog6,
    dateCreated: "8.Nov 2022",
    url: "team-building-2022",
  },
  {
    id: 5,
    title: "Let’s meet!",
    subtitle: "Open House Day 2022",
    imageUrl: blog5,
    dateCreated: "17.Jun 2022",
    url: "open-house-day-2022",
  },
  {
    id: 4,
    title: "Clean Earth, green Earth",
    subtitle: "CleanUp Day",
    imageUrl: blog4,
    dateCreated: "02.Jun 2022",
    url: "cleanup-day-2022",
  },
  {
    id: 3,
    title: "Laugh worries away",
    subtitle: "Mental Health Awareness Month",
    imageUrl: blog3,
    dateCreated: "27.May 2022",
    url: "mental-health-month-2022",
  },
  {
    id: 2,
    title: "Wishing you joyful New year",
    subtitle: "",
    imageUrl: blog2,
    dateCreated: "05.Jan 2022",
    url: "new-years-celebration-2022",
  },
  {
    id: 1,
    title: "Charging batteries",
    subtitle: "Team building 2021",
    imageUrl: blog1,
    dateCreated: "04.Nov 2021",
    url: "team-building-2021",
  },
];
